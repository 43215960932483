<template>
  <div class="account-wrap">
    <permission-detail v-if="route.fullPath.includes('detail')"></permission-detail>
    <permission-list v-else></permission-list>
  </div>
</template>

<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutations } from '@/utils/mapStore';
export default {
  name: 'manage-permission',
  components: {
    PermissionList: defineAsyncComponent(() => import('@/components/account/PermissionList.vue')),
    PermissionDetail: defineAsyncComponent(() => import('@/components/account/PermissionDetail.vue')),
  },
  setup() {
    const route = useRoute();
    // 離開清空vuex
    const RESET = useMutations('permissionManagement', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/account/permission')) RESET();
      }
    );
    return { route };
  },
};
</script>

<style lang="scss" scoped>
.account-wrap {
  width: 100%;
  height: 100%;
}
</style>
